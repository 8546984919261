<template>
  <img
    style="width: 40px"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFJSURBVGhD7ZjNDoIwEAZ5GL14B72ZiO//EpioN3+4a1f7adQCq2zbjdlJeiCQ7YwhQigMwzAMg8tpVc2O6/nUH4pDs2kPfygLDW5X5f5cl5sYETTTzW7autyJR0C+rasLLemIp/x9vmjEuzyWVMSnvF9uT5GIw3IxCW7g1tiITnmB2S/EiEgmDyQjksuDgYiGs3E2eTAmIrs8+CVCjTz4JkKdPOBEqJUH/YLVllb4nAJ50BcRWqrkATdCpTy4R4RvGVon95KmVp4YCqBzyn991i3EemInhSuPpSqiT37gbzR/RL8860HW0MPQj0sLR95fOnht8ohv5IGaiF/kQfaIMfIgW4SEPPCzNl2zxCMk5UGyiBjyIEnE7cOWewkLbTBGHnRGSH3YIt4jpOTBR4SkPECEtDx4RMSQBzQ4hjyg2dHkDcMwjD+kKK4QNAs7byc1WQAAAABJRU5ErkJggg=="
  />
</template>

<script>
export default {}
</script>
