<template>
  <img
    style="width: 40px"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAM/SURBVFhH7ZhLSFRhGIanCIKINrVo1a5d1KZttMjL3KKCaGEUtJCCFhW1CYuJQJ1zzogXSoeKLhQUQURgEUFoFz3/OQ6VJBFiUFIRFSXOTKOmnv5Xvx/G6fPMODozLnzhgcP5/u99X7zxO54lLRZtD3WsrtTEAYBner04JAut8Bt238FL7xIAz3hH49KrUjOPVUXfxttiEw7AM97RuLTaUWet9RvWsN4Zd6KyHMAz3mFGx0qngG5fPnJrIKXKKfAOMzpWGpXXdm0KRKxkizk6oxzAO8xwho4XXwHDNk/d/zKRWU6BGc7Q8eKqQuvavaf5VbytZ5wtBzDDGZylteLI2/xoZcCwvp5/8pstlg7O4Cx2aL3w8oatGvy94wpx4Cx2aL2w8uvWekmi4XmSLcOBs9jBLtkUTvLv252jtz+OcEXcwA52yaYwKtOsrTsjdvKCGGNLuIEd7MKD7BZYjrMsELF7Tz/4NskVAErcDGAXHvAi14WTV+vev7fldTzaw4cDJW42hdyFB7zIdmEUDMVW+XXxs/bpEB9MKHEzBTzgBU+yn798mqg/dPV9kgtMR4mbpQMveJL9/FQWfrHBp4tkY1eKDUtHiZulAy94wpti8pf8oW4/fndwjAvKRImbZQJPeFNMfpLX9207G3qSrfZfNiQTJW6WCTzhjQyKm5tCIWe5vLr3n334gw3gUOJmHPBGBrIoNnf5wmb1vou9CVzhOXMOJW7GAW9kIItic5M3JNb4DTEU7hxmjWdDiZvNBjKQhUyKz66AIVqqb/T/d43PhhI3cwNZyKR4d5Vp5kZ56/jT3D3CmrmhxM3cQBYykU01ZlcwYnWcvPd5nDMqJMhENtXgVV4nKnY1xhKtLtd4N66/mYabZQOZyEYHqjNT+CRAXs0Hzz3+xRpkA8VGx50p8i2JbHRgP5Xwa+JEVbQv52t8JukFr+VZEKADulCtaQUjsXU+3YobzxLsUq7M51usQAd0QSeqJwvq9pXDNz/M+RpfKNAFnageLqMi1ZTDbaVYNL1MOV5dpKie/Aoa9kBN+/dJt9ty0ZAdzsguwYjdT/U8nop6sUX+xzVYGTadxYD8Tf7k1c3NVG9JSyqhPJ5/RTebdIxP0S8AAAAASUVORK5CYII="
  />
</template>

<script>
export default {}
</script>
