<template>
  <img
    style="width: 40px"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAI0SURBVGhD7ZhNK0RRGMdnY+5GWSk772+ZyEvZWqAkSZGFKAsiNBoNIiRlRUoaG0VWpHwAmzEWVj6BjJW9DzB1r/Nc5x63M8+de2bmvhx1/vWr05znnPs8N/NrElFRUVFRUfEjekYb1p+j2V+0Ifrx/4j+UllNBvg2MpoBwBo+o9vyR89EU1bzf0NEU3Rb7ujpihh547n8AbQc7NEyeUPe9BPfvAXs0TI5Q76wY1jjdqCGlssV4y1SQf5M3rGm7Zg1pJYekyfkzSawhjGglh6TI7w23YBaqbSKadMNabTqpM34eI8xO9Bvsk7W/D6ckUKrTtrcmOhmA2xOduXtA6FrtZA2d6Y72QCwxmqA0LTqps2DmRgb4GguhtYA5h1haNVNm9C0NcDxfAdaYxG4VkW0ebLQzgY4XWxHayzgrkC1KqLNs6U2NsD5SitaYycwrTppkye11sIGgDVWYwfuDESrTtrkuUo0swGuk01oDY/vWi2kTZ6bZCMb4HarAa3B8E2ror82Le5269kA93t1aA2G+Qw/tOqmTZ6H/Vo2wONhLVrjhOdaLfbXZrnAszzVqog2vcYzrYpqk+frocq4jLeYwBqrKQQ80xOtimqTZ3uqi30HYI3VuFG2VovRJs/ySB8bANZYjQgla7VYbfK8XtQYq6O9JrDGakQweyhFq8Vq009K0io5lMUuCwPohbYlHnLoE7ssDMiX+YO2JR74lzgcxC4MErOHtDZI21JRUVFRUbElEvkBB3ktvFH9fsYAAAAASUVORK5CYII="
  />
</template>

<script>
export default {}
</script>
